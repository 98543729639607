#cover-image {
  pointer-events: none;
}

#home-content {
  pointer-events: none;
}

#home-content h1,
#home-content h2,
#home-content h3,
#home-content h4,
#home-content p {
  color: white;
}
#home-content h1 {
  margin-top: 0;
  font-size: 18vmin;
  letter-spacing: -0.2vmin;
  margin-bottom: 0px;
  margin-left: 2vw;
  margin-right: 2vw;
  text-transform: uppercase;
}

#home-content h3 {
  font-size: 3vw;
  text-transform: uppercase;
  margin-top: 16px;
  margin-bottom: 16px;
}

#home-content h4 {
  font-size: 4.5vmin;
  letter-spacing: 1vmin;
  margin-bottom: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

#home-content p {
  font-size: 4vmin;
  line-height: 5vmin;
  margin-bottom: 3vh;
  margin-top: 8vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

#home-content p b {
  font-size: 5vmin;
}

#home-testimonial-name {
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
  text-align: end;
  position: absolute;
  bottom: 24px;
  right: 24px;
}

#home-testimonial-name #home-f1 {
  margin: 0;
  font-size: 4vmin;
}

#home-testimonial-name #home-driver-name {
  margin: 0;
  margin-top: 1vh;
  font-size: 6vmin;
}

#skip-home {
  position: fixed;
  top: 2vh;
  right: 2vw;
}

#skip-home h3 {
  color: white;
  text-transform: uppercase;
}

#audio-button-home {
  height: 120px;
  display: flex;
  align-items: center;
  pointer-events: all;
  justify-content: center;
}

@media only screen and (min-width: 992px) {
  #home-content p {
    margin-top: 15vh;
  }

  #home-content h1 {
    font-size: 16vh;
  }

  #home-content h3 {
    margin-bottom: 36px;
  }

  #home-content p {
    margin-bottom: 40px;
  }

  #home-testimonial-name {
    bottom: 60px;
    right: 60px;
  }
}
