#scene {
  overflow: hidden;
}

#scene-testimonial {
  position: absolute;
  top: 25%;
  transition: var(--transition-time-fast);
}

#scene #scene-main-button {
  position: fixed;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.scene-testimonial-closed {
  left: 0%;
}

.scene-testimonial-open {
  left: 75%;
  transition: var(--transition-time-fast);
}

@media only screen and (min-width: 992px) {
  #scene-testimonial {
    /* top: 40%; */
  }

  .scene-testimonial-open {
    left: 85%;
  }
}
