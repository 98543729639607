/* TransitionRoute */
.page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transition: opacity 500ms;
}

.page-enter {
  z-index: -1;
}

.page-exit {
  z-index: 2;
}
