#challenge-description {
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 16px;
}

#challenge-description h2,
#challenge-description h3 {
  color: white;
  text-align: right;
  margin: 0;
}

#challenge-description h2 {
  font-size: 4vmin;
  font-weight: 700;
  text-transform: uppercase;
}

#challenge-description h3 {
  font-size: 2.8vmin;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
}

#challenge-description h1 {
  position: absolute;
  font-size: 16vmin;
  letter-spacing: 6px;
  color: var(--color-background-transparent);
  font-weight: 700;
  margin-top: 0;
}

#challenge-description #text-container {
  padding-right: 20px;
  padding-top: 10px;
  border-right: 1px solid var(--color-rotax-red);
}

#challenge-description-number-only {
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--color-background-transparent);
  transition: 0.5s;
  cursor: pointer;
}

#challenge-description-number-only h1 {
  font-size: 32px;
  color: #ffffffcc;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 26px;
  margin-bottom: 22px;
  padding: 0;
  font-weight: 700;
  bottom: 0;
}
