#finish-content {
  position: fixed;
  width: 60vw;
  top: 20vh;
  left: 20vw;
  align-items: center;
  justify-content: center;
}

#form-disclaimer {
  color: white;
  opacity: 0.75;
}

#form-disclaimer a {
  color: white;
  opacity: 0.75;
}

#finish-form-modal {
  background-color: #1f212de6;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

#finish-form-close {
  position: absolute;
  top: 24px;
  right: 32px;
  width: 24px;
  height: 24px;
}

#more-info-mobile {
  position: fixed;
  right: 0;
  top: 88px;
  bottom: 88px;
  width: 40px;
  padding-right: 24px;
  cursor: pointer;
  background-color: var(--color-background-highlight);
}

#more-info-mobile p {
  width: 100%;
  height: 100%;
  font-weight: 700;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  color: white;
}

#more-info-mobile-fullscreen {
  padding-left: 32px;
  padding-right: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-color: #101117cc;
  box-shadow: 5px 5px 15px hsla(0, 0%, 0%, 0.267);
}

#more-info-video {
  position: fixed;
  right: 0;
  top: calc(50% - 100px);
  height: 200px;
  width: 240px;
  transition: 0.5s;
}

#more-info-video.closed {
  right: -240px;
}

@media only screen and (min-width: 992px) {
  #finish-content {
    width: 80vw;
    left: 10vw;
    top: 0;
    justify-content: flex-start;
  }
  #finish-content #rhombus-button {
    margin-top: 2vh;
  }
}

@media only screen and (min-width: 1280px) {
  #finish-content {
    width: 60vw;
    left: 20vw;
  }
  #finish-content #rhombus-button {
    margin-top: 2vh;
  }
}
