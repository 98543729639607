#overview-element {
  pointer-events: all;
}

#overview-element #track-image {
  width: 150px;
  height: 40px;
  margin-right: 20px;
}

#overview-element #top-container {
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
}

#overview-element #back-image {
  width: 30px;
  height: 20px;
}

#overview-element #track-info-header-container,
#back-container {
  height: 80px;
  cursor: pointer;
}

#overview-element #track-info-header,
#back-text {
  color: white;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 16px;
  margin-right: 16px;
  letter-spacing: 0.4vmin;
}

#overview-audio-button {
  width: 80px;
  align-self: flex-end;
}

#overview-element #track-image {
  margin-top: 24px;
  opacity: 0.9;
}

@media only screen and (min-width: 992px) {
  #overview-element #track-info-header,
  #back-text {
    font-size: 18px;
    letter-spacing: 0.4vmin;
  }

  #overview-audio-button {
    width: 120px;
  }

  #overview-element #track-info-header-container,
  #back-container {
    height: 120px;
  }

  #overview-element #back-image {
    width: 50px;
    height: 25px;
  }
}
