#redeem-form-container #rhombus-button {
  margin: 0;
}

#redeem-form-container input {
  background-color: transparent;
  border: none;
  border-bottom: 4px solid var(--color-yellow);
  font-size: 30px;
  width: 100%;
  margin-bottom: 0;
  color: white;
  text-align: left;
}

#finish-email-input::placeholder {
  color: white;
  opacity: 0.5;
}

#redeem-userid-input {
  margin-left: 16px;
}

#redeem-page h1 {
  color: white;
  font-size: 8vmin;
  margin-top: 0;
  margin-bottom: 8vh;
  font-weight: 700;
  text-transform: uppercase;
}

#redeem-page p {
  margin-bottom: 24px;
  opacity: 0.8;
  font-size: 20px;
  line-height: 26px;
  margin-left: 30%;
  margin-right: 30%;
  text-align: center;
}
