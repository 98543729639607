#rhombus-button {
  transform: skew(-25deg);
  background-color: var(--color-yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  margin-left: 24px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 0;
  margin-bottom: 0;
  height: 44px;
  transition: 0.25s;
  pointer-events: all;
  outline: none; /* removes the "border" when clicked in Chrome */

  overflow: hidden;
  position: relative;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);

  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
}

#rhombus-button.plain {
  background-color: transparent;
  color: white;
}

#rhombus-button:active {
  color: black;
  transform: skew(-25deg) scale(1.05);
}

#rhombus-button.plain:active {
  color: white;
}

#rhombus-button:disabled {
  cursor: default;
}

#rhombus-button:hover {
  background-position: -100% top;
  box-shadow: inset 0 0 18px rgba(255, 255, 255, 0.6);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
}

#rhombus-button.plain:hover {
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.8);
}

#rhombus-button:disabled:hover {
  transform: skew(-25deg) scale(1);
}

#rhombus-button-content {
  transform: skew(25deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#rhombus-button:after {
  background: #fff;
  content: "";
  height: 100%;
  left: -75px;
  opacity: 0.8;
  position: absolute;
  transform: rotate(0deg);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 10;
}

#rhombus-button:hover:after {
  left: 120%;
}

#rhombus-button h5 {
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  padding-top: 4px;
  color: black;
  margin: 0;
}

#rhombus-button.android h5 {
  padding-top: 0px;
}

@media only screen and (min-width: 992px) {
  #rhombus-button {
    padding-left: 60px;
    padding-right: 60px;
    height: 55px;
  }

  #rhombus-button h5 {
    font-size: 24px;
    line-height: 24px;
  }
}
