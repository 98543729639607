#testimonial-overlay {
  position: absolute;
  margin-left: -10%;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 23, 31, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  transition: var(--transition-time-fast);
  z-index: 20;
  overflow: hidden;
  padding: 0;
}

#testimonial-content {
  margin-left: 15%;
  width: 100%;
  max-height: 100%;
  text-align: left;
  overflow: auto;
  display: flex;
}

#testimonial-content h5 {
  color: white;
  font-size: 16px;
  line-height: 20px;
}

#testimonial-content h4 {
  color: var(--color-rotax-red);
  font-size: 25px;
  letter-spacing: 5px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

#testimonial-content h3 {
  color: #e8e8e9;
  font-size: 8vh;
  margin: 0;
  text-align: left;
  margin-bottom: 20px;
  letter-spacing: -1.85px;
  text-transform: uppercase;
}

#testimonial-content ol {
  list-style: none;
  counter-reset: li;
  padding: 0;
}

#testimonial-content li::before {
  content: counter(li);
  color: var(--color-rotax-red);
  font-size: 6vh;
  line-height: 6.5vh;
  padding-right: 30px;
  font-weight: 700;
}

#testimonial-content li {
  color: white;
  counter-increment: li;
  display: flex;
  align-items: center;
  font-size: 5vh;
  letter-spacing: -0.75px;
  margin-bottom: 16px;
}

#testimonial-overlay-images {
  margin-left: auto;
  margin-right: auto;
}

#testimonial-overlay-images img {
  max-height: 30vh;
  margin-bottom: 4vh;
}

#testimonial-overlay #spacer {
  height: 40px;
}

#testimonial-overlay-close {
  position: absolute;
  top: 16px;
  right: 24px;
  height: 24px;
  width: 24px;
}

@media only screen and (min-width: 992px) {
  #testimonial-content li {
    font-size: 3vh;
    line-height: 3.2vh;
  }

  #testimonial-overlay {
    height: 90%;
    top: 8%;
    border-radius: 10px;
    border: 1px solid #ffffff80;
  }

  #testimonial-content h5 {
    font-size: 30px;
    line-height: 36px;
  }

  #testimonial-content h3 {
    font-size: 8vh;
    margin-bottom: 64px;
  }
}
