:root {
  --color-yellow: #ece200;
  --color-rotax-red: #e72f36;
  --color-orange-highlight: #f2b826;
  --color-accent: #008fd3;
  --color-green: #4fb81c;
  --color-background: #101117;
  --color-background-transparent: #ffffff10;
  --color-background-highlight: #0c1b25;
  --color-background-highlight-transparent: #0c1b2500;
  --color-tag: #4e0e4e;
  --color-tag-unselected: #1d2b32;
  --color-headline: #ffffff;
  --color-text: #e0e0e0;
  --transition-time-fast: 0.5s;
}

body {
  margin: 0;
  font-family: "TradeGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  background-color: var(--color-background);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  /* Prevent user from selecting any text */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  -webkit-tap-highlight-color: transparent;
}

html {
  /* needed for fullscreen background color */
  background-color: var(--color-background);
}

input {
  font-family: "TradeGothic", sans-serif;
}

p {
  color: white;
  margin: 0;
}

button {
  font-family: "TradeGothic", sans-serif;
  border: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "TradeGothic";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/trade-gothic-no-18-condensed.ttf) format("truetype");
}

@font-face {
  font-family: "TradeGothic";
  font-style: bold;
  font-weight: 700;
  src: url(./fonts/trade-gothic-no-20-condensed-bold.ttf) format("truetype");
}
