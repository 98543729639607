#more-info-video-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

#more-info-bottom {
  background-color: var(--color-rotax-red);
  margin: 0;
  margin-top: -4px;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
}

#more-info-video-container:hover #more-info-bottom {
  background-color: var(--color-yellow);
  color: black;
}

#more-info-bottom a {
  color: inherit;
  text-decoration: inherit;
}

#more-info-close {
  position: relative;
  height: 16px;
  width: 16px;
}

@media only screen and (min-width: 992px) {
  #more-info-video-container {
    display: flex;
    flex-direction: column;
  }

  #more-info-close {
    margin-bottom: 8px;
  }

  #more-info-bottom {
    min-height: 60px;
  }
}

@media only screen and (max-width: 993px) {
  #more-info-close {
    position: absolute;
    top: 32px;
    right: 32px;
    height: 16px;
    width: 16px;
  }

  #more-info-bottom {
    margin-top: 1px;
    margin-left: -2px;
    flex: 0 0 20%;
  }
}
