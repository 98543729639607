#tunnel-explanation-images {
  display: flex;
}

#tunnel-explanation-first {
  position: relative;
}

#tunnel-explanation-checkmark {
  position: absolute;
  width: 50%;
  left: 25%;
  bottom: -20%;
}

#tunnel-explanation-images img {
  opacity: 0.5;
}

#tunnel-explanation-first img {
  opacity: 1;
}

#tunnel-explanation-dialog #dialog-popover {
  height: 100px;
}

#tunnel-explanation-image {
  height: 30vh;
  padding: 8px;
  padding-bottom: 0;
}

@media only screen and (max-height: 440px) {
  #dialog-popover.tunnel-images h1 {
    margin-bottom: 4px;
  }

  #dialog-popover.tunnel-images h2 {
    margin-bottom: 8px;
  }

  #dialog-popover.tunnel-images h4 {
    margin-bottom: 8px;
    font-size: 18px;
  }

  #dialog-popover.tunnel-images .dialog-button {
    margin-top: 16px;
  }

  #dialog-popover.tunnel-images p {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) {
  #dialog-popover.tunnel-images h1 {
    margin-bottom: 0;
  }

  #dialog-popover.tunnel-images p {
    margin-bottom: 12px;
  }

  #tunnel-explanation-image {
    height: 15vh;
    padding: 16px;
  }
}
