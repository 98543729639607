#share-content {
  margin-right: 2.5vmin;
}

#share-content h5 {
  color: white;
  font-size: 2.2vmin;
  margin: 0;
  text-align: center;
  opacity: 0.8;
  text-transform: uppercase;
}

#share-content p {
  font-size: 2.2vmin;
  margin: 0;
  text-align: center;
  opacity: 0.8;
}
