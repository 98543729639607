.red-circle-accelerate {
   margin-left: auto;
   margin-right: auto;
   left: 0;
   right: 0;
   bottom: 10%;
   position: absolute;
}

@media only screen and (min-width: 992px) {
   .red-circle-accelerate {
     bottom: 25%;
   }
 }