#track-corner-game .red-circle-track-corner1 {
  position: absolute;
  top: 20%;
}

#track-corner-game .red-circle-track-corner2 {
  position: absolute;
  top: 28%;
}

#select-racing-line {
  position: fixed;
  bottom: 5%;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
