#scene-overlay {
  position: fixed;
  display: grid;
  grid-template-rows: auto auto;
  align-content: space-between;
  pointer-events: none;
  align-items: flex-start;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-default-columns {
  grid-template-columns: 3fr 1fr 3fr;
}

.overlay-home-track-columns {
  grid-template-columns: 1fr 0fr 6fr;
}

#scene-overlay-rotax-logo {
  width: 100px;
  padding-left: 24px;
  padding-top: 20px;
}

#scene-overlay-lap-time {
  padding-top: 16px;
}

#overview-right-element-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  #overview-right-element-container {
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  #scene-overlay-rotax-logo {
    width: 120px;
    padding-top: 24px;
    padding-left: 56px;
  }

  #scene-overlay-lap-time {
    padding-top: 24px;
  }
}
