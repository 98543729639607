#track-overview #background-image {
  opacity: 0;
}

#track-overview .red-circles {
  position: absolute;
  width: 11vw;
  height: 11vw;
  transition: 0.3s;
  z-index: 100;
  opacity: 0;
}

#track-overview .red-circles-inactive {
  transform: scale(0);
  opacity: 0;
}

#track-overview .red-circles-active {
  transform: scale(1.1);
  opacity: 1;
}

#track-overview .red-circle-rl {
  left: 12%;
  top: 46%;
}

#track-overview .red-circle-kart {
  left: 45%;
  top: 72%;
}

#track-overview .red-circle-tunnel {
  left: 65%;
  top: 20%;
}

#track-overview .info-window h2 {
  color: white;
  font-weight: 700;
  font-size: 6.5vmin;
  text-transform: uppercase;
  margin-bottom: 4vmin;
  letter-spacing: 1vmin;
  line-break: strict;
  text-align: start;
}

#track-overview .info-window p {
  color: white;
  font-weight: 400;
  font-size: 3.5vmin;
  line-height: 5vmin;
  text-transform: uppercase;
  white-space: pre-wrap;
  text-align: start;
}

#track-overview .info-window {
  position: fixed;
  left: 4%;
  bottom: 0;
  width: 40vw;
  transition: .5s;
  padding: 5vmin;
  z-index: 10;
}

#track-overview .info-window-inactive {
  opacity: 0;
}

#track-overview .info-window-active {
  opacity: 1;
}

@media only screen and (min-width: 992px) {
 
  #track-overview .info-window {
    left: 0;
    width: 50vw;
  }
}