#share-container {
  height: 80px;
}

#share-container .collapsed {
  width: 0px;
  transition: 0.25s;
}

#share-container .expanded {
  width: 60px;
  transition: 0.25s;
}

#icon,
#share-icon {
  display: flex;
  background-color: var(--color-background-transparent);
  align-items: center;
  pointer-events: all;
  justify-content: center;
  transition: 0.25s;
}

#icon svg {
  width: 20px;
  transition: 0.25s;
}

#share-icon svg {
  width: 40px;
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.25s;
}

#icon:hover,
#share-icon:hover {
  background-color: #9b9b9b9c;
}

@media only screen and (min-width: 992px) {
  #share-container {
    height: 120px;
  }

  #share-container .expanded {
    width: 120px;
  }

  #icon svg {
    width: 28px;
    margin-left: 16px;
    margin-right: 16px;
    transition: 0.25s;
  }

  #share-icon svg {
    width: 70px;
    margin-left: 24px;
    margin-right: 24px;
    transition: 0.25s;
  }
}
