#red-circle-button-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55vmin;
  height: 55vmin;
}

#red-circle-button-arrows .red-circle-button-animation-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

#red-circle-button-arrows #red-circle-button {
  position: absolute;
}

@media only screen and (min-width: 992px) {
  #red-circle-button-arrows {
    width: 34vmin;
    height: 34vmin;
  }
}
