#tunnel-mode {
  position: fixed;
  pointer-events: none;
  display: flex;
  align-items: center;
  left: 16px;
  bottom: 16px;
}

#tunnel-mode h1,
#tunnel-mode h2,
#tunnel-mode h3 {
  margin: 0;
}

#tunnel-mode h2 {
  text-align: left;
  font-size: 36px;
  font-weight: 700;
  color: var(--color-yellow);
  text-transform: uppercase;
}

#tunnel-mode h3 {
  text-align: left;
  font-size: 14px;
  margin-bottom: 0.5vh;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
}

#tunnel-mode h1 {
  position: fixed;
  font-size: 100px;
  letter-spacing: 12px;
  color: white;
  opacity: 0.1;
  text-align: left;
  font-weight: 700;
  margin-left: 12vw;
}

#tunnel-mode #tunnel-text-container-next {
  padding-left: 20px;
  padding-top: 10px;
  width: 260px;
  max-width: 25vw;
  border-left: 1px solid var(--color-yellow);
}

#tunnel-mode #tunnel-text-container {
  padding-left: 20px;
  padding-top: 10px;
  width: 260px;
  max-width: 25vw;
  display: flex;
  align-items: center;
  border-left: 1px solid var(--color-yellow);
}

#tunnel-mode #tunnel-mode-container {
  display: flex;
}

#tunnel-mode #tunnel-checkmark-container {
  position: absolute;
  margin-left: 50px;
}

#tunnel-mode #tunnel-checkmark {
  width: 75px;
  height: 75px;
}

#tunnel-mode .transitionX-50 {
  transform: translateX(-50%);
  transition: 0.75s;
}

@media only screen and (min-width: 992px) {
  #tunnel-mode h2 {
    font-size: 70px;
  }

  #tunnel-mode h3 {
    font-size: 24px;
  }

  #tunnel-mode h1 {
    font-size: 16vmin;
    margin-left: 12vw;
  }

  #tunnel-mode #tunnel-checkmark {
    width: 150px;
    height: 150px;
  }

  #tunnel-mode #tunnel-checkmark-container {
    margin-left: 100px;
  }
}
