#testimonial-container {
  position: fixed;
  width: 65vh;
  left: -15vh;
  top: 5%;
}

#testimonial-image {
  position: relative;
  z-index: 10;
}

#testimonial-container #testimonial-arrow-gone {
  opacity: 0;
  transition: 0.5s;
}

#testimonial-container #testimonial-arrow {
  position: absolute;
  right: 18%;
  top: 48%;
  opacity: 1;
  transition: 0.5s;
  animation-duration: 0.8s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
}

@keyframes pulse {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(10px);
  }
}

#testimonial-spoken-text {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 25%;
  top: 54%;
  text-align: left;
  display: flex;
  font-size: 18px;
  line-height: 23px;
  margin-left: 20px;
  margin-top: 0px;
  opacity: 0.85;
}

#testimonial-spoken-text #animated-paragraph {
  margin-top: 16px;
  margin-left: 24px;
}

#testimonial-spoken-text #quote-icon {
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  z-index: -1;
  opacity: 0.2;
}

#testimonial-text-container-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: #1f212dcc;
  border-radius: 10px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

#testimonial-text-container-mobile p {
  text-align: left;
  font-size: 22px;
  line-height: 30px;
  margin-left: 48px;
}

#testimonial-text-mobile-close p {
  color: var(--color-rotax-red);
  margin: 0;
  margin-top: 8px;
}

@media only screen and (min-width: 992px) {
  #testimonial-container {
    width: 50vh;
    left: -12vh;
    top: 10%;
  }
}
