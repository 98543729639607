#lap-time {
  margin-top: 6px;
}

#lap-time h4 {
  position: relative;
  font-size: 24px;
  line-height: 24px;
  color: white;
  margin-top: 0;
  margin-bottom: 2px;
  text-align: left;
}

#lap-time p {
  position: relative;
  font-size: 14px;
  color: white;
  text-align: left;
  transition: 0.2s;
}

#lap-time-stopwatch {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

@media only screen and (min-width: 992px) {
  #lap-time h4 {
    font-size: 35px;
  }

  #lap-time p {
    font-size: 15px;
  }

  #lap-time-stopwatch {
    width: 40px;
    height: 40px;
  }
}
