#finish-form {
  margin-top: 3vh;
}

#finish-form h1 {
  color: white;
  font-size: 11vmin;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 700;
  text-transform: uppercase;
}

#finish-form h3 {
  color: var(--color-rotax-red);
  font-size: 5vmin;
  letter-spacing: 0.3vmin;
  margin-bottom: 8px;
  font-weight: 700;
  text-transform: uppercase;
}

#finish-email-input {
  background-color: transparent;
  border: none;
  border-bottom: 4px solid var(--color-yellow);
  font-size: 4vmin;
  width: 20vw;
  margin-bottom: 0;
  color: white;
  text-align: left;
}

#finish-email-input::placeholder {
  color: white;
  opacity: 0.5;
}

#finish-boost-message {
  margin-bottom: 24px;
  opacity: 0.8;
  font-size: 3.7vmin;
  line-height: 4.2vmin;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
}

#form-disclaimer {
  margin-left: 8px;
}

#finish-form-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#finish-form-container #rhombus-button {
  margin-top: 5vh;
  margin-left: 0;
  margin-right: 0;
}

#finish-share-icon {
  width: 48px;
  height: 48px;
  margin-left: 24px;
  margin-right: 24px;
}

#finish-error {
  color: red;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}

#finish-share-icon path {
  fill: var(--color-rotax-red);
}

@media only screen and (max-width: 993px) {
  #finish-form-container #rhombus-button {
    margin-top: 3vh;
  }
}

@media only screen and (min-width: 992px) {
  #finish-email-input {
    font-size: 2.5vmin;
    min-width: 140px;
  }
  #finish-form-container {
    margin-top: 4vh;
  }
  #finish-form h1 {
    font-size: 9vmin;
    margin-bottom: 24px;
  }
  #finish-form h3 {
    font-size: 3vmin;
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1280px) {
  #finish-email-input {
    font-size: 2.5vmin;
    min-width: 140px;
  }
  #finish-boost-message {
    margin-bottom: 4vh;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  #finish-form h1 {
    margin-bottom: 24px;
  }
  #finish-form h3 {
    margin-bottom: 24px;
  }
}
