#intro h1 {
  color: white;
  text-transform: uppercase;
  font-size: 5vmin;
  width: 50vw;
}

#intro-container {
  margin-bottom: 16px;
  justify-content: space-around;
}

#intro-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#intro-container #rhombus-button {
  margin: 0;
  margin-top: 60px;
}

#intro #progress {
  margin-top: 36px;
}

#intro #progress-text {
  margin-top: 8px;
  font-size: 12px;
}

#intro #progress {
  background-color: white;
  border-radius: 1px;
  height: 2px;
  width: 100%;
}

#intro #progress > div {
  background-color: var(--color-rotax-red);
  height: 100%;
  border-radius: 1px;
  transition: 1.5s;
}

#intro .progress-0 {
  width: 0%;
}

#intro .progress-100 {
  width: 100%;
}

#intro #progress-text {
  text-transform: uppercase;
  opacity: 0.8;
}

#intro #audio-toggle {
  position: fixed;
  top: 0;
  right: 24px;
}

@media only screen and (min-width: 992px) {
  #intro h1 {
    font-size: 4vmin;
    width: 40vw;
    letter-spacing: 0.4vmin;
    line-height: 1.1;
  }
  #intro-container {
    margin-bottom: 12vh;
    justify-content: flex-end;
  }
  #intro-container #rhombus-button {
    margin: 0;
  }
  #intro #progress-text {
    font-size: 18px;
  }
}
