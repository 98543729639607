#finish-result-content {
  background-color: #33333333;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-top: 24px;
}

#mobile-progress-indicator {
  margin-left: 8px;
  margin-right: 8px;
}

#mobile-progress-indicator h5 {
  color: var(--color-yellow);
  font-size: 18px;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 12px;
}

#mobile-progress-indicator p {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
}

#finish-result-content-title {
  position: relative;
  color: white;
  font-size: 7vh;
  margin: 0;
  padding: 8px;
  text-transform: uppercase;
}

#medal-wrapper {
  margin-bottom: 4vh;
}

@media only screen and (min-width: 992px) {
  #finish-result-content-title {
    font-size: 4vh;
  }
  #medal-wrapper {
    margin-bottom: 2vh;
  }
}
