#audio-toggle {
  display: flex;
  height: 80px;
  align-items: center;
  pointer-events: all;
  justify-content: center;
  transition: 0.25s;
}

#audio-toggle button {
  background-color: unset;
}

#audio-toggle svg .cls-1,
#audio-toggle svg .cls-2 {
  fill: white;
}

#audio-toggle svg .cls-1,
#audio-toggle svg .cls-2,
#audio-toggle svg .cls-3 {
  stroke: white;
}
