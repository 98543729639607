#red-circle-button {
  width: 38vmin;
  height: 38vmin;
  cursor: pointer;
}

#red-circle-button .red-circle-button-animation {
  position: absolute;
  width: 100%;
  height: 100%;
}

#red-circle-button #circle-button-text-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
}

#red-circle-button #circle-button-text {
  color: white;
  text-transform: uppercase;
  font-size: 2vmin;
  line-height: 2.5vmin;
  line-height: 5vh;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.3vmin;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  #red-circle-button #circle-button-text {
    font-size: 2.3vh;
    line-height: 2.7vh;
  }
}

@media only screen and (min-width: 992px) {
  #red-circle-button {
    width: 24vmin;
    height: 24vmin;
  }

  #red-circle-button #circle-button-text {
    font-size: 1.3vmin;
    line-height: 1.7vmin;
  }
}
