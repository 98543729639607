#dialog-popover {
  padding: 36px;
  background-color: #101117CC;
  box-shadow: 5px 5px 15px hsla(0, 0%, 0%, 0.267);
  padding-left: 5%;
  padding-right: 5%;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 99999;
}

#dialog-popover h1,
#dialog-popover h2,
#dialog-popover h3,
#dialog-popover p {
  color: white;
  text-align: center;
  margin: 0;
}

#dialog-popover h1 {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 45px;
}

#dialog-popover h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
}

#dialog-popover h4 {
  color: var(--color-rotax-red);
  margin-bottom: 16px;
  margin-top: 0px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 22px;
  text-align: center;
  font-weight: 700;
}

#dialog-popover p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

#dialog-popover .dialog-button {
  margin-top: 40px;
}

@media only screen and (max-width: 992px) {
  #dialog-popover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (min-width: 992px) {
  #dialog-popover {
    min-width: 35%;
    min-height: 40%;
  }

  #dialog-popover h1 {
    font-size: 60px;
    line-height: 65px;
  }
}
