#home-button {
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--color-background-transparent);
  transition: 0.5s;
  padding-left: 22px;
  cursor: pointer;
}

#home-button:hover #home-button-text {
  transform: translateX(0%);
  width: 96px;
  opacity: 1;
}

#home-button #home-button-text {
  transform: translateX(40%);
  color: white;
  width: 0px;
  overflow: hidden;
  transition: 0.5s;
  opacity: 0;
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  margin: 0;
  text-transform: uppercase;
}

#home-button #home-button-icon-container {
  display: inline-block;
}

#home-button #home-button-icon {
  width: 42px;
  margin-right: 22px;
  margin-bottom: 28px;
  margin-top: 24px;
}

@media only screen and (min-width: 992px) {
  #home-button {
    padding-left: 30px;
  }

  #home-button #home-button-icon {
    width: 60px;
    margin-right: 30px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
