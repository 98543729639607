#progress-content {
  margin-left: 8px;
  margin-right: 8px;
}

#progress-content p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-top: 2px;
}

.progress-ring__circle {
  transition: 0.55s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
