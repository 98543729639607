#tunnel #red-circle-button {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 15vh;
  margin-left: auto;
  margin-right: auto;
}

#tunnel-learn-button {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 15vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  height: 50px;
  width: 200px;
  background-color: #e72f3622;
  border: 1px solid var(--color-rotax-red);
  cursor: pointer;
}

#tunnel-learn-button:hover {
  background-color: #e72f3633;
}

#tunnel-learn-button p {
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#tunnel #skip-tunnel {
  position: fixed;
  bottom: 2vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#tunnel #skip-tunnel h3 {
  color: white;
  opacity: 0.5;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 5px;
}

#tunnel .tunnel-info {
  color: white;
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 3px;
  font-weight: 700;
  display: flex;
  text-align: left;
  align-items: center;
  height: 26px;
  opacity: 0;
}

#tunnel .info-1-visible {
  top: 41%;
  left: 63%;
  right: 18vw;
  opacity: 1;
  transition: 0.5s;
}

#tunnel .info-2-visible {
  top: 27%;
  left: 40%;
  right: 18vw;
  opacity: 1;
  transition: 0.5s;
}

#tunnel .tunnel-info .icon-checkmark {
  width: 18px;
  height: 26px;
  margin-right: 12px;
}

#tunnel .tunnel-info .icon-cross {
  height: 14px;
  width: 26px;
  margin-right: 12px;
}

@media only screen and (min-width: 992px) {
  #tunnel #red-circle-button {
    bottom: 10vh;
  }

  #tunnel-learn-button {
    bottom: 10vh;
  }

  #tunnel #skip-tunnel h3 {
    letter-spacing: 6px;
    line-height: 24px;
    font-size: 20px;
  }

  #tunnel .tunnel-info {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 6px;
    height: 40px;
  }

  #tunnel .info-1-visible {
    top: 38%;
  }

  #tunnel .info-2-visible {
    top: 23%;
  }

  #tunnel .tunnel-info .icon-checkmark {
    width: 26px;
    height: 32px;
    margin-right: 16px;
  }

  #tunnel .tunnel-info .icon-cross {
    height: 22px;
    width: 40px;
    margin-right: 16px;
  }
}
