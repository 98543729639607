#rotate-explanation-content {
    padding: 36px;
}

#rotate-explanation-paragraph {
    font-size: 22px;
    font-weight: 700;
}

#rotate-explanation {
  font-size: 22px;
  margin-top: 16px;
  color: white;
}

#rotate-explanation li {
    margin-top: 8px;
}
