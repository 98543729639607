#track-boost .boost-button {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 28vh;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 992px) {
  #track-boost .boost-button {
    bottom: 35vh;
  }
}
